/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Xl8SubtitleType {
    JSON = 'json',
    IYUNO_JSON = 'iyuno.json',
    XL8_JSON = 'xl8.json',
    SENTENCES_JSON = 'sentences.json',
    PAC = 'pac',
    SCC = 'scc',
    SRT = 'srt',
    DFXP = 'dfxp',
    TTML = 'ttml',
    IMSC = 'imsc',
    ITT = 'itt',
    VTT = 'vtt',
    XIF = 'xif',
    STL = 'stl',
    EBU_STL = 'ebu.stl',
    SPRUCE_STL = 'spruce.stl',
    SMI = 'smi',
    XLSX = 'xlsx',
    TXT = 'txt',
}