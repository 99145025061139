import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  JobRequestCollection,
  JobRequestOrigination,
  JobRequestsService,
  JobRequestStatus,
  JobRequestSummary
} from "gen/clients/llts";
import { useQuery } from "react-query";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { formatDate } from "utils/dateUtils";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "usehooks-ts";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import { JobRequestState } from "components/jobRequests/JobRequestFilter/JobRequestState";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { RenameJobRequestDialog } from "components/jobRequests/RenameJobRequestDialog/RenameJobRequestDialog";
import { JobRequestUtils } from "utils/jobRequestUtils";
import {
  JobRequestFilter,
  JobRequestFilterSearchParams
} from "components/jobRequests/JobRequestFilter/JobRequestFilter";
import { Box } from "@mui/material";
import { ArchiveJobRequestDialog } from "components/jobRequests/ArchiveJobRequestDialog/ArchiveJobRequestDialog";

interface Props {
  cacheId: string;
  origination: JobRequestOrigination;
  refetchRef: React.MutableRefObject<(() => void) | undefined>;
  getDetailsPagePath: (jobRequestId: string) => string;
}

const JobRequestList: React.FC<Props> = ({ cacheId, origination, refetchRef, getDetailsPagePath }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useSessionStorage<number>(`${cacheId}.rowsPerPage.1`, 10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const [selectedJobRequest, setSelectedJobRequest] = React.useState<JobRequestSummary>();
  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] = useDialogState();
  const [isArchiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [filterParams, setFilterParams] = React.useState<JobRequestFilterSearchParams>();
  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });
  React.useEffect(() => {
    paginatorResetRef?.current();
  }, [rowsPerPage]);

  const { data, isLoading, error, refetch } = useQuery(
    ["listJobRequests", rowsPerPage, pageToken, filterParams?.statuses],
    {
      queryFn: () =>
        JobRequestsService.listJobRequests({
          status: filterParams?.statuses?.join(","),
          origination,
          pageToken,
          pageSize: rowsPerPage
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      },
      refetchInterval: (jrCollection: JobRequestCollection | undefined) =>
        jrCollection?.items.some(jr => JobRequestUtils.isInProgress(jr.status)) ? 10000 : false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      cacheTime: 0
    }
  );

  // eslint-disable-next-line no-param-reassign
  refetchRef.current = refetch;

  const rowKey = React.useCallback((jobRequest: JobRequestSummary) => jobRequest.id, []);
  const onRowClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      history.push(getDetailsPagePath(jobRequest.id));
    },
    [getDetailsPagePath, history]
  );
  const nameCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Link
        onClick={e => {
          e.preventDefault();
          onRowClick(jobRequest);
        }}
        href={getDetailsPagePath(jobRequest.id)}
      >
        <Typography variant="body2">{jobRequest.name}</Typography>
      </Link>
    ),
    [getDetailsPagePath, onRowClick]
  );
  const sourceLanguageCell = React.useCallback((jobRequest: JobRequestSummary) => jobRequest.sourceLanguage?.name, []);
  const targetLanguagesCell = React.useCallback(
    (jobRequest: JobRequestSummary) =>
      jobRequest.targetLanguages
        ?.map(l => l.name)
        .sort((l1, l2) => l1.localeCompare(l2))
        .join(", "),
    []
  );
  const submittedOnCell = React.useCallback((jobRequest: JobRequestSummary) => formatDate(jobRequest.createTime), []);
  const statusCell = React.useCallback(
    (jobRequest: JobRequestSummary) => <JobRequestState status={jobRequest.status} variant="body2" />,
    []
  );

  const onRowsPerPageChange = React.useCallback(
    (newRowsPerChange: number) => {
      setRowsPerPage(newRowsPerChange);
      setPageToken(undefined);
      setNextPageToken(undefined);
      paginatorResetRef?.current();
    },
    [setRowsPerPage]
  );

  const onRenameActionClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      setSelectedJobRequest(jobRequest);
      openRenameDialog();
    },
    [openRenameDialog]
  );

  const onRenameSuccess = React.useCallback(() => {
    refetch();
    closeRenameDialog();
  }, [closeRenameDialog, refetch]);

  const onArchiveActionClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      setSelectedJobRequest(jobRequest);
      openArchiveJobRequestDialog();
    },
    [setSelectedJobRequest, openArchiveJobRequestDialog]
  );

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestDialog();
  }, [refetch, closeArchiveJobRequestDialog]);

  const onFilterSearch = React.useCallback(
    (searchParams: JobRequestFilterSearchParams) => {
      setFilterParams(searchParams);
      paginatorResetRef?.current();
      refetch();
    },
    [refetch, setFilterParams]
  );

  const rowActions = React.useCallback(
    (jobRequest: JobRequestSummary) => [
      {
        title: t("components.jobRequestList.actions.viewDetails"),
        action: onRowClick
      },
      {
        title: t("components.jobRequestList.actions.rename"),
        action: onRenameActionClick,
        disabled:
          jobRequest.status === JobRequestStatus.ARCHIVED ||
          jobRequest.status === JobRequestStatus.CREATED ||
          jobRequest.status === JobRequestStatus.SUBMITTING_TO_XTRF
      },
      {
        title: t("components.jobRequestList.actions.archive"),
        action: onArchiveActionClick,
        disabled:
          jobRequest.status === JobRequestStatus.ARCHIVED ||
          jobRequest.status === JobRequestStatus.CREATED ||
          jobRequest.status === JobRequestStatus.SUBMITTING_TO_XTRF
      }
    ],
    [onArchiveActionClick, onRenameActionClick, onRowClick, t]
  );

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <JobRequestFilter initialValues={filterParams} onSearch={onFilterSearch} />
      </Box>
      <DataTable
        data={data?.items || []}
        columns={[
          {
            id: "name",
            title: <Typography fontWeight="bold">{t("components.jobRequestList.name")}</Typography>,
            cell: nameCell
          },
          {
            id: "sourceLanguage",
            title: <Typography fontWeight="bold">{t("components.jobRequestList.sourceLanguage")}</Typography>,
            cell: sourceLanguageCell
          },
          {
            id: "targetLanguage",
            title: <Typography fontWeight="bold">{t("components.jobRequestList.targetLanguages")}</Typography>,
            cell: targetLanguagesCell
          },
          {
            id: "submittedOn",
            title: <Typography fontWeight="bold">{t("components.jobRequestList.submittedOn")}</Typography>,
            cell: submittedOnCell
          },
          {
            id: "status",
            title: <Typography fontWeight="bold">{t("components.jobRequestList.status")}</Typography>,
            cell: statusCell
          }
        ]}
        rowKey={rowKey}
        error={!!error && <ApiErrorMessage apiError={error} />}
        isLoading={isLoading}
        onRowClick={onRowClick}
        dense={true}
        rowActions={rowActions}
      />
      <TokenBasedPaginator
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageTokenChange={setPageToken}
        nextPageToken={nextPageToken}
        currentPageDataRows={data?.items.length}
        resetRef={paginatorResetRef}
      />

      {isRenameDialogOpen && selectedJobRequest && (
        <RenameJobRequestDialog
          jobRequestId={selectedJobRequest.id}
          oldName={selectedJobRequest.name}
          onClose={closeRenameDialog}
          onSuccess={onRenameSuccess}
        />
      )}
      {isArchiveJobRequestDialogOpen && selectedJobRequest && (
        <ArchiveJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
    </>
  );
};

export { JobRequestList };
