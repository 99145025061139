import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { FileTranslationMainPage } from "./FileTranslationMainPage/FileTranslationMainPage";
import { MtJobRequestDetailsPage } from "../components/MtJobRequestDetailsPage/MtJobRequestDetailsPage";

const FileTranslationTab: React.FC = () => {
  const history = useHistory();
  const { jobRequestId } = useParams<{ jobRequestId?: string }>();

  const onReturnFromDetailsClick = React.useCallback(
    () => history.push("/machineTranslation/fileTranslation"),
    [history]
  );

  if (jobRequestId) {
    return <MtJobRequestDetailsPage jobRequestId={jobRequestId} onReturnClick={onReturnFromDetailsClick} />;
  }

  return <FileTranslationMainPage />;
};

export { FileTranslationTab };
