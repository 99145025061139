import * as React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { JobRequestStatus } from "gen/clients/llts";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { JobRequestUtils } from "utils/jobRequestUtils";
import { MtRequestStatus, MtRequestUtils } from "pages/MachineTranslationPage/utils/MtRequestUtils";

interface LocalProps {
  status: JobRequestStatus;
}

type Props = LocalProps & TypographyProps;

const StatusComp: React.FC<{ status: MtRequestStatus; inProgress?: boolean } & TypographyProps> = ({
  status,
  color,
  inProgress,
  ...others
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    {inProgress && <CircularProgress size={10} />}
    <Typography color={color} {...others}>
      {status}
    </Typography>
  </Stack>
);

const JobRequestState: React.FC<Props> = ({ status, ...others }) => {
  const mtStatus = MtRequestUtils.getMtRequestStatus(status);

  const getColorForStatus = () => {
    switch (status) {
      case JobRequestStatus.COMPLETED:
      case JobRequestStatus.SUBMITTED_TO_XTRF:
        return "success.main";

      case JobRequestStatus.CANCELLED:
      case JobRequestStatus.CANCELLING:
      case JobRequestStatus.FAILED:
        return "error.main";

      case JobRequestStatus.ARCHIVED:
        return "gray";

      default:
        return "info.main";
    }
  };

  return (
    <StatusComp
      color={getColorForStatus()}
      status={mtStatus}
      inProgress={JobRequestUtils.isInProgress(status)}
      {...others}
    />
  );
};

export { JobRequestState };
