/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { GetXl8DubbingVoicesResponse } from '../models/GetXl8DubbingVoicesResponse';
import type { Language } from '../models/Language';
import type { LltsLanguage } from '../models/LltsLanguage';
import type { LogMessage } from '../models/LogMessage';
import type { PresignedPost } from '../models/PresignedPost';
import type { Province } from '../models/Province';
import type { Xl8LanguageCombinations } from '../models/Xl8LanguageCombinations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UtilsService {

    /**
     * Returns a list of languages available in XTRF
     * @returns Language Returns a list of all languages
     * @throws ApiError
     */
    public static listLanguages(): CancelablePromise<Array<Language>> {
        return __request({
            method: 'GET',
            path: `/languages`,
        });
    }

    /**
     * Returns a list of languages available in LLTS
     * @returns LltsLanguage Returns a list of Llts languages
     * @throws ApiError
     */
    public static listLltsLanguages({
        xtrfIdExists,
        intentoIdExists,
        smartMateIdExists,
        azureIdExists,
        xl8FileTranslationIdExists,
        xl8SyncIdExists,
        xl8DubbingIdExists,
    }: {
        /** is Xtrf mapping configured **/
        xtrfIdExists?: boolean,
        /** is Intento mapping configured **/
        intentoIdExists?: boolean,
        /** is SmartMate mapping configured **/
        smartMateIdExists?: boolean,
        /** is Azure Translator mapping configured **/
        azureIdExists?: boolean,
        /** is XL8 File Translation mapping configured **/
        xl8FileTranslationIdExists?: boolean,
        /** is XL8 Sync mapping configured **/
        xl8SyncIdExists?: boolean,
        /** is XL8 Dubbing mapping configured **/
        xl8DubbingIdExists?: boolean,
    }): CancelablePromise<Array<LltsLanguage>> {
        return __request({
            method: 'GET',
            path: `/lltsLanguages`,
            query: {
                'xtrfIdExists': xtrfIdExists,
                'intentoIdExists': intentoIdExists,
                'smartMateIdExists': smartMateIdExists,
                'azureIdExists': azureIdExists,
                'xl8FileTranslationIdExists': xl8FileTranslationIdExists,
                'xl8SyncIdExists': xl8SyncIdExists,
                'xl8DubbingIdExists': xl8DubbingIdExists,
            },
        });
    }

    /**
     * Returns a list of countries available in XTRF
     * @returns Country Returns a list of countries
     * @throws ApiError
     */
    public static listCountries(): CancelablePromise<Array<Country>> {
        return __request({
            method: 'GET',
            path: `/countries`,
        });
    }

    /**
     * Returns a list of provinces available in XTRF
     * @returns Province Returns a list of provinces
     * @throws ApiError
     */
    public static listProvinces(): CancelablePromise<Array<Province>> {
        return __request({
            method: 'GET',
            path: `/provinces`,
        });
    }

    /**
     * Returns a map where a key is a XL8 translation language code and value is an array of XL8 trans language codes
     * @returns Xl8LanguageCombinations A map where a key is a XL8 translation language code and value is an array of XL8 trans language codes
     * @throws ApiError
     */
    public static getXl8FileTranslationLanguageCombinations({
        sourceLanguageId,
    }: {
        /** XL8 file translation code of the source language **/
        sourceLanguageId?: string,
    }): CancelablePromise<Xl8LanguageCombinations> {
        return __request({
            method: 'GET',
            path: `/xl8/fileTranslation/languageCombinations`,
            query: {
                'sourceLanguageId': sourceLanguageId,
            },
        });
    }

    /**
     * Returns a map where a key is a XL8 dubbing language code and value is an array of voices
     * @returns GetXl8DubbingVoicesResponse A map where a key is a XL8 dubbing language code and value is an array of voices
     * @throws ApiError
     */
    public static getXl8DubbingVoices(): CancelablePromise<GetXl8DubbingVoicesResponse> {
        return __request({
            method: 'GET',
            path: `/xl8/dubbing/dubbingVoices`,
        });
    }

    /**
     * Creates a log record in CloudWatch.  Intended for logging UI event on the server.
     * @returns any Successfully accepted request.
     * @throws ApiError
     */
    public static logUiMessage({
        requestBody,
    }: {
        /** log message **/
        requestBody?: LogMessage,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/uiLogs`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a presigned URL for uploading files to temp S3 bucket
     * @returns PresignedPost successful operation
     * @throws ApiError
     */
    public static createTempFileUploadPresignedPost(): CancelablePromise<PresignedPost> {
        return __request({
            method: 'POST',
            path: `/tempFiles/actions/createPresignedPost`,
        });
    }

}