/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JobRequestOrigination {
    EMAIL = 'EMAIL',
    WEBFORM = 'WEBFORM',
    MT = 'MT',
    COUPA = 'COUPA',
    MEDIAAI = 'MEDIAAI',
}