import * as React from "react";
import { Drawer } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import {
  CreateMediaTranslationForm,
  FieldName,
  FormValues
} from "./CreateMediaTranlsationForm/CreateMediaTranslationForm";
import { useQuery } from "react-query";
import {
  DubbingLanguageAndVoice,
  JobRequestFile,
  JobRequestFileCategory,
  LltsLanguage,
  MachineTranslationService,
  UtilsService,
  Xl8DubbingVoice,
  Xl8SubtitleType
} from "gen/clients/llts";
import { SelectOption } from "@mui/base";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { useUserSession } from "hooks/useUserSession";
import { useS3FileUpload } from "hooks/useS3FileUpload/useS3FileUpload2";
import { AlertDialog } from "components/AlertDialog/AlertDialog";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import { useTranslation } from "react-i18next";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { createProjectName } from "utils/projectUtils";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const MAX_TARGET_LANGUAGES = 10;
const MAX_TOTAL_FILES_SIZE_BYTES = 250 * 1024 * 1024; // 250 MB
const DEFAULT_MAX_AUDIO_FILE_SIZE_MB = 1;
const DEFAULT_MAX_VIDEO_FILE_SIZE_MB = 1;

const CreateMediaTranslationPanel: React.FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { username } = useUserSession();
  const [selectedSourceLanguageId, setSelectedSourceLanguageId] = React.useState<string>();
  const [selectedTargetLanguageIds, setSelectedTargetLanguageIds] = React.useState<string[]>([]);
  const [isDubbingSelected, setDubbingSelected] = React.useState<boolean>(false);
  const {
    invoke: uploadFiles,
    isUploading: areFilesUploading,
    error: filesUploadError,
    uploadProgress,
    reset: resetS3FileUpload
  } = useS3FileUpload();

  const {
    data: mtSettings,
    isLoading: areMtSettingsLoading,
    error: mtSettingsError
  } = useQuery(["getMtSettings"], {
    queryFn: MachineTranslationService.getMachineTranslationSettings
  });
  const {
    data: lltsLanguages,
    isLoading: areLltsLanguagesLoading,
    error: lltsLanguagesError
  } = useQuery(["listLltsLanguages", "mediaAi"], {
    queryFn: () => UtilsService.listLltsLanguages({ xl8SyncIdExists: true, xl8FileTranslationIdExists: true })
  });

  const {
    data: xl8TransLanguageCombinations,
    isLoading: areXl8TransLangCombinationsLoading,
    error: xl8TransLanguageCombinationsError
  } = useQuery(["getXl8FileTranslationLanguageCombinations"], {
    queryFn: () => UtilsService.getXl8FileTranslationLanguageCombinations({})
  });

  const {
    data: xl8DubbingVoices,
    isLoading: areXl8DubbingVoicesLoading,
    error: xl8DubbingVoicesError
  } = useQuery(["getXl8DubbingVoices"], {
    queryFn: UtilsService.getXl8DubbingVoices,
    enabled: isDubbingSelected
  });

  const isLoading = areLltsLanguagesLoading || areXl8TransLangCombinationsLoading || areMtSettingsLoading;
  const error = lltsLanguagesError || xl8TransLanguageCombinationsError || xl8DubbingVoicesError || mtSettingsError;

  const { lltsLanguageById, lltsLanguageByXl8FileTranslationId } = React.useMemo(() => {
    const lltsLanguageById: Record<string, LltsLanguage> = {};
    const lltsLanguageByXl8FileTranslationId: Record<string, LltsLanguage> = {};
    (lltsLanguages || []).forEach(lltsLang => {
      lltsLanguageById[lltsLang.id] = lltsLang;
      if (lltsLang.xl8FileTranslationId) {
        lltsLanguageByXl8FileTranslationId[lltsLang.xl8FileTranslationId] = lltsLang;
      }
    });
    return { lltsLanguageById, lltsLanguageByXl8FileTranslationId };
  }, [lltsLanguages]);

  const sourceLanguageOptions: SelectOption<string>[] = React.useMemo(() => {
    if (!Object.keys(lltsLanguageByXl8FileTranslationId).length || !xl8TransLanguageCombinations) {
      return [];
    }
    return Object.keys(xl8TransLanguageCombinations)
      .map(sourceLanguageXl8TransId => lltsLanguageByXl8FileTranslationId[sourceLanguageXl8TransId])
      .filter(l => l !== undefined)
      .map(l => ({ label: l.name, value: l.id }))
      .sort(SELECT_OPTION_COMPARATOR);
  }, [lltsLanguageByXl8FileTranslationId, xl8TransLanguageCombinations]);

  const targetLanguageOptions: SelectOption<string>[] = React.useMemo(() => {
    if (!selectedSourceLanguageId) {
      return [];
    }
    const sourceLanguageOption: SelectOption<string> = {
      label: lltsLanguageById[selectedSourceLanguageId].name,
      value: selectedSourceLanguageId
    };
    const sourceLanguageXl8Id = lltsLanguageById[selectedSourceLanguageId].xl8FileTranslationId;
    if (!sourceLanguageXl8Id) {
      return [sourceLanguageOption];
    }
    return [sourceLanguageOption]
      .concat(
        (xl8TransLanguageCombinations || {})[sourceLanguageXl8Id]
          .map(xl8LangId => lltsLanguageByXl8FileTranslationId[xl8LangId])
          .filter(l => l !== undefined)
          .map(l => ({
            label: l.name,
            value: l.id
          }))
      )
      .sort(SELECT_OPTION_COMPARATOR);
  }, [lltsLanguageById, lltsLanguageByXl8FileTranslationId, selectedSourceLanguageId, xl8TransLanguageCombinations]);

  const dubbingVoicesByTargetLanguage: Map<LltsLanguage, Xl8DubbingVoice[]> = React.useMemo(() => {
    const result = new Map<LltsLanguage, Xl8DubbingVoice[]>();
    if (!selectedTargetLanguageIds.length || !xl8DubbingVoices) {
      return result;
    }
    selectedTargetLanguageIds.forEach(langId => {
      const lltsLanguage = lltsLanguageById[langId];
      const xl8DubLangCode = lltsLanguage.xl8DubbingId;
      if (xl8DubLangCode && langId !== selectedSourceLanguageId) {
        const voices = xl8DubbingVoices[xl8DubLangCode];
        result.set(lltsLanguage, voices);
      }
    });
    return result;
  }, [lltsLanguageById, selectedSourceLanguageId, selectedTargetLanguageIds, xl8DubbingVoices]);

  const initialValues = React.useMemo(() => {
    const initVals: FormValues = {
      [FieldName.projectName]: createProjectName(username),
      [FieldName.files]: [],
      [FieldName.sourceLanguage]: null,
      [FieldName.targetLanguages]: [],
      [FieldName.subtitlesType]: Xl8SubtitleType.SRT,
      [FieldName.requestDubbing]: false
    };
    lltsLanguages?.forEach(l => (initVals[`${FieldName.dubbingVoicePrefix}#${l.id}`] = ""));
    return initVals;
  }, [lltsLanguages, username]);

  const validate = React.useCallback(
    (values: FormValues) => {
      const errors: Record<FieldName, string> = {} as Record<FieldName, string>;
      const sourceLanguageId = values[FieldName.sourceLanguage]?.value;
      const targetLanguageIds = values[FieldName.targetLanguages]?.map(o => o.value);
      if (targetLanguageIds && targetLanguageIds.length > MAX_TARGET_LANGUAGES) {
        errors[FieldName.targetLanguages] = t("machineTranslation.mediaTranslation.validation.maxTargetLanguages");
      }
      const files = values[FieldName.files];
      const totalFilesSize = files.reduce((prev, currentFile) => prev + currentFile.size, 0);
      if (totalFilesSize >= MAX_TOTAL_FILES_SIZE_BYTES) {
        errors[FieldName.files] = t("machineTranslation.mediaTranslation.validation.maxTotalFilesSize");
      }

      files.forEach(file => {
        if (
          file.type.startsWith("audio/") &&
          file.size > (mtSettings?.maxAudioFileSizeMB || DEFAULT_MAX_AUDIO_FILE_SIZE_MB) * 1024 * 1024
        ) {
          errors[FieldName.files] = t("machineTranslation.mediaTranslation.validation.maxAudioFileSize", {
            filename: file.name,
            maxFileSizeMB: mtSettings?.maxAudioFileSizeMB || DEFAULT_MAX_AUDIO_FILE_SIZE_MB
          });
        }

        if (
          file.type.startsWith("video/") &&
          file.size > (mtSettings?.maxVideoFileSizeMB || DEFAULT_MAX_VIDEO_FILE_SIZE_MB) * 1024 * 1024
        ) {
          errors[FieldName.files] = t("machineTranslation.mediaTranslation.validation.maxVideoFileSize", {
            filename: file.name,
            maxFileSizeMB: mtSettings?.maxVideoFileSizeMB || DEFAULT_MAX_VIDEO_FILE_SIZE_MB
          });
        }
      });

      // Validate the dubbing request by checking that selected target languages support dubbing
      if (values[FieldName.requestDubbing] && dubbingVoicesByTargetLanguage.size > 0) {
        const voiceLanguageLltsIds = Array.from(dubbingVoicesByTargetLanguage.entries()).map(
          ([language]) => language.id
        );
        const languagesNotSupportingDubbing = values[FieldName.targetLanguages]
          .filter(
            targetLangOption =>
              !voiceLanguageLltsIds.includes(targetLangOption.value) && targetLangOption.value !== sourceLanguageId
          )
          .map(l => l.label);
        if (languagesNotSupportingDubbing.length > 0) {
          errors[FieldName.targetLanguages] = t(
            "machineTranslation.mediaTranslation.validation.dubbingNotSupportedLanguages",
            { languages: languagesNotSupportingDubbing.join(", ") }
          );
        }
      }
      return errors;
    },
    [dubbingVoicesByTargetLanguage, mtSettings?.maxAudioFileSizeMB, mtSettings?.maxVideoFileSizeMB, t]
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      resetS3FileUpload();
      const files = values[FieldName.files];
      await uploadFiles({
        files,
        onSuccess: async fileKeys => {
          const jobRequestFiles: JobRequestFile[] = files.map((file, index) => ({
            fileName: file.name,
            category: JobRequestFileCategory.SOURCE_DOCUMENT,
            languageId: values[FieldName.sourceLanguage]?.value,
            fileKey: fileKeys[index]
          }));
          const isDubbingRequested = values[FieldName.requestDubbing];
          const dubbingVoices: DubbingLanguageAndVoice[] | undefined = !isDubbingRequested
            ? undefined
            : Object.keys(values)
                .filter(
                  key =>
                    key.startsWith(FieldName.dubbingVoicePrefix) &&
                    values[key as `${FieldName.dubbingVoicePrefix}#${string}`]
                )
                .map(key => ({
                  languageId: key.split("#")[1],
                  voiceName: values[key as `${FieldName.dubbingVoicePrefix}#${string}`]
                }));
          await MachineTranslationService.translateMediaFiles({
            requestBody: {
              requestName: values[FieldName.projectName],
              files: jobRequestFiles,
              sourceLanguageId: values[FieldName.sourceLanguage]!.value!,
              targetLanguageIds: values[FieldName.targetLanguages].map(o => o.value),
              subtitlesType: values[FieldName.subtitlesType],
              isDubbingRequested,
              dubbingVoices
            }
          });
          onSuccess();
        }
      });
    },
    [onSuccess, resetS3FileUpload, uploadFiles]
  );

  return (
    <Drawer
      open={true}
      anchor="right"
      PaperProps={{ sx: { width: isSmallScreen ? "100vw" : "50vw" } }}
      onClose={onClose}
    >
      <DrawerHeader title={t("machineTranslation.mediaTranslation.createPanelTitle")} onClose={onClose} />
      <DrawerBody isLoading={isLoading} error={error}>
        {filesUploadError && <SnackbarApiError error={filesUploadError} />}
        <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
          {mtSettings && (
            <CreateMediaTranslationForm
              mtSettings={mtSettings}
              sourceLanguageOptions={sourceLanguageOptions}
              targetLanguageOptions={targetLanguageOptions}
              areXl8DubbingVoicesLoading={areXl8DubbingVoicesLoading}
              dubbingVoicesByTargetLanguage={dubbingVoicesByTargetLanguage}
              onSourceLanguageChange={setSelectedSourceLanguageId}
              onTargetLanguagesChange={setSelectedTargetLanguageIds}
              onRequestDubbingChange={setDubbingSelected}
              onCancel={onClose}
            />
          )}
        </Formik>
        {areFilesUploading && (
          <AlertDialog title={t("machineTranslation.mediaTranslation.uploadingAlert.title")}>
            <Stack spacing={1}>
              <Box>{t("machineTranslation.mediaTranslation.uploadingAlert.message")}</Box>
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={uploadProgress} />
              </Box>
            </Stack>
          </AlertDialog>
        )}
      </DrawerBody>
    </Drawer>
  );
};

export { CreateMediaTranslationPanel };
