import * as React from "react";
import { ApiError, UtilsService } from "../../gen/clients/llts";
import { S3FileUploader } from "../../utils/S3FileUploader";
import { createFileKey } from "../../utils/s3Utils";

interface Props {
  files?: File[];
  onSuccess?: (fileKeys: string[]) => Promise<void> | void;
}

interface Response {
  invoke: (props: Props) => Promise<void>;
  isUploading: boolean;
  isSuccess?: boolean;
  error?: ApiError;
  uploadProgress: number;
  reset: () => void;
}

export const useS3FileUpload = (): Response => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean>();
  const [error, setError] = React.useState<ApiError>();
  const [uploadedBytes, setUploadedBytes] = React.useState(0);
  const [totalBytesToUpload, setTotalBytesToUpload] = React.useState(0);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  React.useEffect(() => {
    if (totalBytesToUpload === 0) {
      setUploadProgress(0);
      return;
    }
    setUploadProgress(((100 * uploadedBytes) / totalBytesToUpload) * 0.95);
  }, [totalBytesToUpload, uploadedBytes]);

  const onUploadProgress = React.useCallback(
    (delta: number) => {
      setUploadedBytes(oldValue => oldValue + delta);
    },
    [setUploadedBytes]
  );

  const invoke = React.useCallback(async ({ files, onSuccess }: Props) => {
    if (!files || !files.length) {
      return;
    }
    setTotalBytesToUpload(files.map(f => f.size).reduce((p, c) => p + c, 0));
    setUploadedBytes(0);
    setIsUploading(true);
    try {
      setIsUploading(true);
      setIsSuccess(undefined);
      setError(undefined);
      const presignedPost = await UtilsService.createTempFileUploadPresignedPost();
      await S3FileUploader.upload(files, presignedPost, onUploadProgress);
      if (onSuccess) {
        const fileKeys = files.map(f => createFileKey(presignedPost, f.name));
        await onSuccess(fileKeys);
      }
      setIsUploading(false);
      setUploadProgress(100);
      setIsSuccess(true);
    } catch (e) {
      setError(e as ApiError);
      setIsUploading(false);
      setIsSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = React.useCallback(() => {
    setIsSuccess(undefined);
    setIsUploading(false);
    setUploadedBytes(0);
    setTotalBytesToUpload(0);
    setUploadProgress(0);
  }, []);

  return {
    invoke,
    isUploading,
    isSuccess,
    error,
    uploadProgress,
    reset
  };
};
