import * as React from "react";
import { Form, useFormikContext } from "formik";
import Stack from "@mui/material/Stack";
import { DropzoneField } from "components/formikFields/DropzoneField/DropzoneField";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { LltsLanguage, MachineTranslationSettings, Xl8DubbingVoice, Xl8SubtitleType } from "gen/clients/llts";
import { Box, CircularProgress } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { CheckboxField } from "components/formikFields/CheckboxField/CheckboxField";
import Typography from "@mui/material/Typography";

const SUPPORTED_FILE_TYPES = [
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-matroska",
  "video/x-flv",
  "video/x-ms-wmv",
  "video/webm",
  "audio/mpeg",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  "audio/ogg",
  "audio/mp4",
  "audio/x-ms-wma"
];

interface Props {
  mtSettings: MachineTranslationSettings;
  sourceLanguageOptions: SelectOption<string>[];
  targetLanguageOptions: SelectOption<string>[];
  areXl8DubbingVoicesLoading: boolean;
  dubbingVoicesByTargetLanguage: Map<LltsLanguage, Xl8DubbingVoice[]>;
  onSourceLanguageChange: (lltsLanguageId: string | undefined) => void;
  onTargetLanguagesChange: (lltsLanguageIds: string[]) => void;
  onRequestDubbingChange: (isDubbingRequested: boolean) => void;
  onCancel: () => void;
}

export enum FieldName {
  projectName = "projectName",
  files = "files",
  sourceLanguage = "sourceLanguage",
  targetLanguages = "targetLanguages",
  subtitlesType = "subtitlesType",
  requestDubbing = "requestDubbing",
  dubbingVoicePrefix = "dubbingVoice"
}

export interface FormValues {
  [FieldName.projectName]: string;
  [FieldName.files]: File[];
  [FieldName.sourceLanguage]: SelectOption<string> | null;
  [FieldName.targetLanguages]: SelectOption<string>[];
  [FieldName.subtitlesType]: Xl8SubtitleType;
  [FieldName.requestDubbing]: boolean;

  [key: `${FieldName.dubbingVoicePrefix}#${string}`]: string;
}

const SUBTITLE_TYPE_OPTIONS: SelectOption<string>[] = Object.values(Xl8SubtitleType)
  .map(type => ({
    label: type,
    value: type
  }))
  .sort(SELECT_OPTION_COMPARATOR);

const CreateMediaTranslationForm: React.FC<Props> = ({
  mtSettings,
  sourceLanguageOptions,
  targetLanguageOptions,
  areXl8DubbingVoicesLoading,
  dubbingVoicesByTargetLanguage,
  onSourceLanguageChange,
  onTargetLanguagesChange,
  onRequestDubbingChange,
  onCancel
}) => {
  const { t } = useTranslation();
  const { values, isSubmitting } = useFormikContext<FormValues>();

  const sourceLanguageId = values[FieldName.sourceLanguage]?.value;
  React.useEffect(() => {
    onSourceLanguageChange(sourceLanguageId);
  }, [onSourceLanguageChange, sourceLanguageId]);

  const targetLanguages = values[FieldName.targetLanguages];
  React.useEffect(() => {
    const targetLanguageIds = targetLanguages.map(l => l.value);
    onTargetLanguagesChange(targetLanguageIds);
  }, [onTargetLanguagesChange, targetLanguages]);

  const requestDubbing = values[FieldName.requestDubbing];
  React.useEffect(() => {
    onRequestDubbingChange(requestDubbing);
  }, [onRequestDubbingChange, requestDubbing]);

  const dubbingVoiceByVoiceName: Record<string, Xl8DubbingVoice> = React.useMemo(() => {
    return Array.from(dubbingVoicesByTargetLanguage.values())
      .flat()
      .reduce<Record<string, Xl8DubbingVoice>>((acc, voice) => {
        acc[voice.voiceName] = voice;
        return acc;
      }, {});
  }, [dubbingVoicesByTargetLanguage]);

  return (
    <Form noValidate={true} autoComplete="off" autoCorrect="off">
      <Stack spacing={3}>
        <DropzoneField
          name={FieldName.files}
          fileUploadLimits={{
            maxFileCount: mtSettings.maxFilesPerRequest,
            acceptedFiles: SUPPORTED_FILE_TYPES
          }}
          dropzoneText={t("machineTranslation.mediaTranslation.filesDropzone.message")}
          required={true}
        />
        <TextInputField
          name={FieldName.projectName}
          label={t("machineTranslation.mediaTranslation.projectName.label")}
          required={true}
        />
        <SelectOneAutocompleteField
          name={FieldName.sourceLanguage}
          label={t("machineTranslation.mediaTranslation.sourceLanguage.label")}
          helperText={t("machineTranslation.mediaTranslation.sourceLanguage.helperText")}
          options={sourceLanguageOptions}
          required={true}
        />
        <SelectManyAutocompleteField
          name={FieldName.targetLanguages}
          label={t("machineTranslation.mediaTranslation.targetLanguages.label")}
          helperText={t("machineTranslation.mediaTranslation.targetLanguages.helperText")}
          options={targetLanguageOptions}
          required={true}
        />
        <SelectOneField
          name={FieldName.subtitlesType}
          label={t("machineTranslation.mediaTranslation.subtitlesType.label")}
          helperText={t("machineTranslation.mediaTranslation.subtitlesType.helperText")}
          options={SUBTITLE_TYPE_OPTIONS}
          required={true}
        />
        <CheckboxField
          name={FieldName.requestDubbing}
          Label={{
            label: (
              <Typography variant="body2">
                {t("machineTranslation.mediaTranslation.requestDubbing.label")} (
                {t("machineTranslation.mediaTranslation.requestDubbing.helperText")})
              </Typography>
            )
          }}
        />
        {values[FieldName.requestDubbing] && (
          <>
            {areXl8DubbingVoicesLoading ? (
              <CircularProgress />
            ) : (
              <Stack spacing={2}>
                {Array.from(dubbingVoicesByTargetLanguage.entries()).map(([language, voices]) => (
                  <Stack key={language.id} direction="row" spacing={2}>
                    <SelectOneField
                      name={`${FieldName.dubbingVoicePrefix}#${language.id}`}
                      label={t("machineTranslation.mediaTranslation.dubbingVoice.label", {
                        language: language.name
                      })}
                      helperText={t("machineTranslation.mediaTranslation.dubbingVoice.helperText", {
                        language: language.name
                      })}
                      options={voices.map(v => ({ label: v.displayName, value: v.voiceName }))}
                      required={true}
                    />
                    {values[`${FieldName.dubbingVoicePrefix}#${language.id}`] &&
                      dubbingVoiceByVoiceName[values[`${FieldName.dubbingVoicePrefix}#${language.id}`]]?.sampleUrl && (
                        <Box key={values[`${FieldName.dubbingVoicePrefix}#${language.id}`]}>
                          <audio
                            controls={true}
                            src={
                              dubbingVoiceByVoiceName[values[`${FieldName.dubbingVoicePrefix}#${language.id}`]]
                                ?.sampleUrl
                            }
                          ></audio>
                        </Box>
                      )}
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        )}
        <Stack direction="row" spacing={1} justifyContent="center" pt={5}>
          <LoadingButton isLoading={isSubmitting} disabled={areXl8DubbingVoicesLoading}>
            {t("machineTranslation.mediaTranslation.submitBtn")}
          </LoadingButton>
          <Button onClick={onCancel}>{t("common.cancel")}</Button>
        </Stack>

        <Box pt={4}>
          <Typography
            variant="body2"
            sx={theme => ({ color: theme.palette.grey["700"] })}
            dangerouslySetInnerHTML={{ __html: t("machineTranslation.mediaTranslation.disclaimer") }}
          />
        </Box>
      </Stack>
    </Form>
  );
};

export { CreateMediaTranslationForm };
