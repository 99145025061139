import * as React from "react";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { JobRequestList } from "components/jobRequests/JobRequestList/JobRequestList";
import { JobRequestOrigination } from "gen/clients/llts";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { CreateMediaTranslationPanel } from "./CreateMediaTranslationPanel/CreateMediaTranslationPanel";
import { useHistory, useParams } from "react-router-dom";
import { MtJobRequestDetailsPage } from "../components/MtJobRequestDetailsPage/MtJobRequestDetailsPage";

const MediaTranslationTab: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { jobRequestId } = useParams<{ jobRequestId?: string }>();
  const listRefetchRef = React.useRef<() => void | undefined>();
  const [isCreatePanelOpen, openCreatePanel, closeCreatePanel] = useDialogState();

  const onReturnFromDetailsClick = React.useCallback(
    () => history.push("/machineTranslation/mediaTranslation"),
    [history]
  );

  const onCreateSuccess = React.useCallback(() => {
    listRefetchRef.current && listRefetchRef.current();
    closeCreatePanel();
  }, [closeCreatePanel]);

  if (jobRequestId) {
    return (
      <MtJobRequestDetailsPage
        jobRequestId={jobRequestId}
        onReturnClick={onReturnFromDetailsClick}
        humanTranslationInfoMessage={t("machineTranslation.mediaTranslation.humanTranslationInfo")}
        translatedFilesHeader={t("machineTranslation.mediaTranslation.translatedFilesHeader")}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Box>
            <Button variant="contained" size="large" onClick={openCreatePanel}>
              {t("machineTranslation.mediaTranslation.createBtn")}
            </Button>
          </Box>
          <JobRequestList
            cacheId="mediaAiJobRequestList"
            origination={JobRequestOrigination.MEDIAAI}
            refetchRef={listRefetchRef}
            getDetailsPagePath={jobRequestId => `/machineTranslation/mediaTranslation/${jobRequestId}`}
          />
        </Stack>
      </Stack>

      {isCreatePanelOpen && <CreateMediaTranslationPanel onClose={closeCreatePanel} onSuccess={onCreateSuccess} />}
    </Container>
  );
};

export { MediaTranslationTab };
